<template>
    <div v-loading="loading">
        <top-nav></top-nav>
        <header-guide></header-guide>
        <main
            class="login-main"
            :style="{backgroundImage:'url('+$img.login_bg+')'}"
        >
            <!--登录模块-->
            <transition name="el-zoom-in-center">
                <div
                    class="login-group position"
                    v-if="login"
                >
                    <div class="form-group">
                        <div class="guide">
                            <a
                                href="javascript:void(0)"
                                @click="openDialog('register')"
                            >免费注册</a>
                        </div>

                        <h2 class="nav">登录您的账号</h2>

                        <el-form
                            ref="loginForm"
                            :rules="loginRules"
                            :model="loginForm"
                            :show-message="true"
                            class="form-wrap"
                        >
                            <el-form-item
                                class="form-item"
                                prop="mobile"
                            >
                                <i class="icon">
                                    <img
                                        class="user"
                                        :src="$img.user"
                                    />
                                </i>
                                <el-input
                                    v-model="loginForm.mobile"
                                    class="w334"
                                    placeholder="手机号"
                                    autocomplete="off"
                                    @focus="inputFocus(0)"
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                class="form-item"
                                prop="password"
                            >
                                <i class="icon">
                                    <img
                                        class="password"
                                        :src="$img.password"
                                    />
                                </i>
                                <el-input
                                    v-model="loginForm.password"
                                    type="password"
                                    class="w334"
                                    placeholder="密码"
                                    autocomplete="off"
                                    @focus="inputFocus(1)"
                                    @keyup.enter.native="loginFn"
                                ></el-input>
                            </el-form-item>

                            <div
                                class="position-bg"
                                :class="positionBgCss"
                            ></div>

                            <el-button
                                class="submit-btn"
                                @click="loginFn"
                                @keyup.enter.native="loginFn"
                            >登录</el-button>
                        </el-form>
                        <div class="auth_title">
                            <span class="line"></span>
                            <span>第三方登录</span>
                            <span class="line"></span>
                        </div>
                        <div class="auth_login">
                            <a @click="qqLogin">
                                <img
                                    class="login_btn"
                                    :src="qq_img"
                                    title="qq登录"
                                />
                            </a>

                            <a @click="weixinLogin">
                                <img
                                    class="login_btn"
                                    :src="weixin_img"
                                    title="微信登录"
                                />
                            </a>
                        </div>
                        <div class="login-guide">
                            <p class="name">忘记密码？</p>
                            <p class="guide-tips">
                                不用担心，
                                <a
                                    href="javascript:void(0)"
                                    @click="openDialog('findPasswordBox')"
                                >点击这里</a> 立即重置密码
                            </p>
                        </div>
                    </div>
                </div>
            </transition>

            <!--注册模块-->
            <transition name="el-zoom-in-center">
                <div
                    class="reg-group position"
                    v-if="register"
                >
                    <div class="role-sel">
                        <h4 class="title">选择注册身份</h4>
                        <el-radio-group
                            v-model="radio"
                            class="role-sel-radio"
                        >
                            <el-radio :label="1">
                                <span
                                    class="avatar"
                                    @click="toggleType(1)"
                                >
                                    <img :src="$img.geek_avatar" />
                                    <img
                                        class="checked"
                                        :src="$img.sel_role"
                                    />
                                    <em class="gray-bg"></em>
                                </span>
                                <p class="name">工程极客</p>
                            </el-radio>

                            <el-radio :label="2">
                                <span
                                    class="avatar"
                                    @click="toggleType(2)"
                                >
                                    <img :src="$img.employer_avatar" />
                                    <img
                                        class="checked"
                                        :src="$img.sel_role"
                                    />
                                    <em class="gray-bg"></em>
                                </span>
                                <p class="name">雇主</p>
                            </el-radio>
                        </el-radio-group>

                        <div class="desc-cont">
                            <h5 class="title">{{radio==1?'注册工程极客':'注册雇主'}}</h5>
                            <div
                                class="desc"
                                v-if="radio==1"
                            >
                                如果您是工程领域的执从业人员，希望寻找兼职或短期工作，您可以注册成为工程极客。
                                工程极客用户成功入驻平台后，可以在平台上搜索项目并报名参加，完成项目，获取酬金。询龙网将成为您展现自我价值，发挥技能与实力的最佳平台。
                            </div>

                            <div
                                class="desc"
                                v-if="radio==2"
                            >
                                如果您有工程方面的需求，希望找到专家与能人为您完成，您可以注册成为雇主。
                                雇主用户可以免费发布项目需求，并通过平台寻找中意的合作伙伴，数万询龙网入驻的工程极客将竭诚为您服务。
                                雇主可发布的项目范围包括但不限于：工程测绘、项目评估、工程勘察、工程设计、工程造价、工程监理、财务会计、税务服务、工程法务。
                            </div>
                        </div>
                    </div>

                    <div class="reg-form form-group">
                        <div class="guide">
                            <a
                                href="javascript:void(0)"
                                @click="openDialog('login')"
                            >登录</a>
                        </div>

                        <h2 class="nav">注册询龙网账号</h2>

                        <el-form
                            ref="registerForm"
                            :model="registerForm"
                            :rules="registerRules"
                            class="form-wrap"
                            id="registerForm"
                        >
                            <el-form-item class="form-item">
                                <i class="icon">
                                    <img
                                        class="user"
                                        :src="$img.user"
                                    />
                                </i>
                                <el-input
                                    v-model="registerForm.nick"
                                    class="w334"
                                    placeholder="用户昵称"
                                    autocomplete="off"
                                    @focus="inputFocus(0)"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                class="form-item"
                                prop="mobile"
                            >
                                <i class="icon">
                                    <img
                                        class="mobile"
                                        :src="$img.mobile"
                                    />
                                </i>
                                <el-input
                                    v-model="registerForm.mobile"
                                    class="w334"
                                    placeholder="手机号"
                                    autocomplete="off"
                                    @focus="inputFocus(1)"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                class="form-item"
                                prop="code"
                            >
                                <i class="icon">
                                    <img
                                        class="yzm"
                                        :src="$img.yzm"
                                    />
                                </i>
                                <el-input
                                    v-model="registerForm.code"
                                    class="w234"
                                    placeholder="验证码"
                                    autocomplete="off"
                                    @focus="inputFocus(2)"
                                ></el-input>
                                <el-button
                                    style="float:right;"
                                    @click="getMobileCode"
                                >{{time==0?'获取验证码':time+'秒后获取'}}</el-button>
                            </el-form-item>

                            <el-form-item
                                class="form-item"
                                prop="password"
                            >
                                <i class="icon">
                                    <img
                                        class="password"
                                        :src="$img.password"
                                    />
                                </i>
                                <el-input
                                    v-model="registerForm.password"
                                    type="password"
                                    class="w334"
                                    placeholder="密码"
                                    autocomplete="off"
                                    @focus="inputFocus(3)"
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                class="form-item"
                                prop="area"
                            >
                                <i class="icon">
                                    <img
                                        class="city"
                                        :src="$img.city"
                                    />
                                </i>
                                <el-cascader
                                    :options="areas"
                                    v-model="registerForm.area"
                                    @focus="inputFocus(4)"
                                    placeholder="请选择您所在省份"
                                ></el-cascader>
                            </el-form-item>

                            <el-form-item class="form-item">
                                <i class="icon">
                                    <img
                                        class="code"
                                        :src="$img.code"
                                    />
                                </i>
                                <el-input
                                    class="w334"
                                    v-model="registerForm.invitationCode"
                                    placeholder="邀请码（选填）"
                                    autocomplete="off"
                                    @focus="inputFocus(5)"
                                ></el-input>
                            </el-form-item>

                            <div :class="positionBgCss"></div>

                            <el-button
                                class="submit-btn"
                                @click="registerFn"
                            >立即注册成为{{userTypeName}}</el-button>
                        </el-form>
                    </div>
                </div>
            </transition>

            <!--找回密码模块-->
            <transition name="el-zoom-in-center">
                <div
                    class="login-group position login_group"
                    v-if="findPasswordBox"
                >
                    <div class="form-group">
                        <div class="guide">
                            <a
                                href="javascript:void(0)"
                                @click="openDialog('login')"
                            >立即登录</a>
                        </div>
                        <h2 class="nav">重置您的账号密码</h2>
                        <el-form
                            ref="findPwdForm"
                            :model="findPwdForm"
                            :rules="findPwdRules"
                            class="form-wrap"
                        >
                            <el-form-item
                                class="form-item"
                                prop="mobile"
                            >
                                <i class="icon">
                                    <img
                                        class="mobile"
                                        :src="$img.mobile"
                                    />
                                </i>
                                <el-input
                                    type="number"
                                    v-model="findPwdForm.mobile"
                                    class="w334"
                                    placeholder="手机号"
                                    autocomplete="off"
                                    @focus="inputFocus(0)"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                class="form-item"
                                prop="code"
                            >
                                <i class="icon">
                                    <img
                                        class="yzm"
                                        :src="$img.yzm"
                                    />
                                </i>
                                <el-input
                                    v-model="findPwdForm.code"
                                    class="w234"
                                    placeholder="验证码"
                                    autocomplete="off"
                                    @focus="inputFocus(1)"
                                ></el-input>
                                <el-button @click="getMobileCodeFindPsd">{{time==0?'获取验证码':time+'秒后获取'}}</el-button>
                            </el-form-item>
                            <el-form-item
                                class="form-item"
                                prop="password"
                            >
                                <i class="icon">
                                    <img
                                        class="password"
                                        :src="$img.password"
                                    />
                                </i>
                                <el-input
                                    v-model="findPwdForm.password"
                                    type="password"
                                    class="w334"
                                    placeholder="密码"
                                    autocomplete="off"
                                    @focus="inputFocus(2)"
                                ></el-input>
                            </el-form-item>
                            <div :class="positionBgCss"></div>
                            <el-button
                                class="submit-btn"
                                @click="findPwdFn"
                            >重置密码</el-button>
                        </el-form>
                    </div>
                </div>
            </transition>

            <!--找回密码成功提示-->
            <transition name="el-zoom-in-center">
                <div
                    class="tips-group position"
                    v-if="findPasswordTips"
                >
                    <img :src="$img.suc_icon" />
                    <h2 class="tips-tit">恭喜您!</h2>
                    <p class="tips-txt">您的账户密码已经修改，请保存好新密码重新登录！</p>
                    <el-button
                        class="submit-btn"
                        @click="openDialog('login')"
                    >返回登录</el-button>
                </div>
            </transition>
        </main>
        <global-footer></global-footer>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "login",
    metaInfo: {
        title: "账号注册登录中心 - 询龙网",
        meta: [
            {
                name: "keywords",
                content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
            }, {
                name: "description",
                content: "询龙网账号中心，在这里可以进行注册、登录、找回密码等操作。"
            }
        ]
    },

    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        GlobalFooter //公共底部
    },

    data() {
        let _this = this;
        return {
            //登录表单
            loginForm: {
                mobile: "",
                password: ""
            },
            qq_img: this.websiteConfigs.sourceUrl + "/upload/image/qq.png",
            weixin_img: this.websiteConfigs.sourceUrl + "/upload/image/weixin.png",
            weibo_img: this.websiteConfigs.sourceUrl + "/upload/image/weibo.png",
            loading: false,
            registerForm: {
                type: 1, //类型
                nick: "", //昵称
                mobile: "", //手机号
                code: "", //手机验证码
                password: "", //密码
                area: [], //区域
                invitationCode: "", //邀请码
                openid: "", //第三方openid
                reg_type: "" //第三方类型
            },
            findPwdForm: {
                mobile: "",
                code: "",
                password: ""
            },
            activeName: "first",
            radio: 1,
            areas: [], //区域
            userTypeName: "工程极客",
            positionBgTop: "0px",
            positionBgCss: "position-bg",
            sendFlag: true,
            avatar: this.$img.geek_avatar,
            options: "", //地区选择
            register: false, //注册窗口
            login: false, //登录窗口
            findPasswordBox: true, //找回密码窗口
            findPasswordTips: false, //找回密码成功
            time: 0, //短信验证码获取时间
            time4: 0,
            loginRules: {
                //登录验证规则
                mobile: [
                    {
                        required: true,
                        message: "手机号码不能为空",
                        trigger: ["change", "blur"]
                    },
                    {
                        validator: _this.validata.mobile(),
                        trigger: ["change", "blur"]
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: ["change", "blur"]
                    }
                ]
            },
            registerRules: {
                area: [
                    {
                        required: true,
                        message: "请选择所在地区",
                        trigger: "blur"
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur"
                    },
                    {
                        validator: this.validata.mobile(),
                        trigger: ["blur", "change"]
                    }
                ],
                code: [
                    {
                        required: true,
                        message: "请输入短信验证码",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "密码必须为6-20位，同时密码必须同时包含有数字和字母。",
                        trigger: "blur"
                    }
                ]
            },
            findPwdRules: {
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur"
                    },
                    {
                        validator: _this.validata.mobile(),
                        trigger: ["change", "blur"]
                    }
                ],
                code: [
                    {
                        required: true,
                        message: "请输入短信验证码",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        min: 6,
                        max: 20,
                        message: "请输入6~20位密码",
                        trigger: "blur"
                    }
                ]
            }
        };
    },

    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]), //刷新用户数据方法
        //打开对应对话框
        openDialog(val) {
            //关闭所有窗口
            this.positionBgCss = "position-bg";
            this.login = false;
            this.register = false;
            this.findPasswordBox = false;
            this.findPasswordTips = false;

            //清空所有表单
            this.registerForm = {};
            this.loginForm = {};
            this.findPwdForm = {};

            switch (val) {
                case "register":
                    this.register = true;
                    break;
                case "login":
                    this.login = true;
                    break;
                case "findPasswordBox":
                    this.findPasswordBox = true;
                    break;
                case "findPasswordTips":
                    this.findPasswordTips = true;
                    break;
                default:
                    this.login = true;
                    break;
            }
        },

        //切换注册类型
        toggleType(t) {
            this.registerForm.type = t;
            if (t == 1) {
                this.userTypeName = "工程极客";
            } else {
                this.userTypeName = "雇主";
            }
        },

        toggleTypeHover(t) {
            this.radio = t;
        },

        tab_click(t) {
            if (t.index == 1) {
                this.$router.push({ name: "register" });
            }
        },

        //跳转到注册页面
        go_register() {
            this.$router.push("/register/index/master");
        },

        //加载区域数据
        load_area() {
            let _this = this;
            _this.post("/system/area/area", { pid: 0 }, function (res) {
                if (res.code == 200) {
                    _this.areas = res.data;
                }
            });
        },

        //用户注册
        registerFn() {
            let _this = this;
            _this.registerForm.user_type = _this.radio; //雇主还是极客（1.雇主；2.极客）
            _this.$refs.registerForm.validate(valid => {
                if (valid) {
                    this.$confirm(
                        "是否注册成为" + _this.userTypeName + "？",
                        "提示",
                        {
                            confirmButtonText: "确定",
                            cancelButtonText: "返回重新选择"
                        }
                    )
                        .then(() => {
                            _this.loading = true;
                            _this.post(
                                "/user/index/register",
                                _this.registerForm,
                                data => {
                                    _this.loading = false;
                                    if (200 !== data.code) {
                                        if (data.msg) {
                                            _this.$message.error(data.msg);
                                        } else {
                                            _this.$message.error("绑定失败");
                                        }
                                        return false;
                                    } else {
                                        sessionStorage.setItem(
                                            "token",
                                            data.data.token
                                        ); //存储登录信息
                                        // _this.$message.success("注册成功");
                                        _this.ajaxUserInfo(); //刷新用户数据
                                        _this.$router.push({
                                            name: "loginsuccess"
                                        }); //跳转到认证提示
                                    }
                                },
                                done => { },
                                error => {
                                    _this.$message.error("网络错误");
                                    _this.loading = false;
                                }
                            );
                        })
                        .catch(() => { });
                }
            });
        },

        //用户登录
        loginFn() {
            let _this = this;
            _this.$refs["loginForm"].validate(valid => {
                if (valid) {
                    _this.post(
                        "/user/index/login",
                        _this.loginForm,
                        data => {
                            _this.loading = false;
                            if (data.code != 200) {
                                if (data.msg) {
                                    _this.$message.error(data.msg);
                                } else {
                                    _this.$message.error("绑定失败");
                                }
                                return;
                            }
                            sessionStorage.setItem("token", data.data.token); //存储登录信息
                            // _this.$message.success("登录成功");
                            _this.ajaxUserInfo(); //刷新用户数据
                            _this.$router.push("/user/index");

                            //跳转回之前页面
                            // let url = sessionStorage.getItem("currentPageUrl");
                            // if (url) {
                            //     sessionStorage.removeItem("currentPageUrl");
                            //     //跳转到上一页
                            //     _this.$router.push(url);
                            // } else {
                            //     _this.$router.push("/user/index");
                            // }
                        },
                        done => { },
                        error => {
                            _this.$message.error("网络错误");
                            _this.loading = false;
                        }
                    );
                }
            });
        },

        //焦点
        inputFocus(val) {
            this.positionBg = true;
            this.positionBgCss = "position-bg position-bg" + val;
        },

        //获取手机验证码
        getMobileCode() {
            let _this = this;
            _this.$refs.registerForm.validateField("mobile", function (
                val,
                error
            ) {
                //手机号码填写正确
                if (!val) {
                    if (_this.time == 0 && _this.sendFlag) {
                        _this.sendFlag = false;
                        _this.loading = true;
                        _this.post(
                            "/user/index/get_sms_code",
                            {
                                mobile: _this.registerForm.mobile,
                                template: "user_reg"
                            },
                            data => {
                                _this.loading = false;
                                _this.sendFlag = true;
                                if (200 !== data.code) {
                                    _this.$message.error(data.msg);
                                    return;
                                }
                                _this.time = 60;
                                _this.startTimer();
                                if (data.data) {
                                    _this.$message({
                                        message: data.msg,
                                        duration: 3000
                                    });
                                }
                            }
                        );
                    }
                }
            });
        },

        //获取手机验证码，找回密码
        getMobileCodeFindPsd() {
            let _this = this;
            _this.$refs["findPwdForm"].validateField("mobile", function (
                val,
                error
            ) {
                //手机号码填写正确
                if (!val) {
                    if (_this.time == 0) {
                        _this.post(
                            "/user/index/get_sms_code",
                            {
                                mobile: _this.findPwdForm.mobile,
                                template: "reset_password"
                            }, res => {
                                if (200 !== res.code) {
                                    _this.$message.error(res.msg);
                                    return;
                                }
                                _this.time = 60;
                                _this.startTimer();
                                _this.$message({
                                    message: res.msg,
                                    duration: 3000
                                });
                            }
                        );
                    }
                }
            });
        },

        //验证码开始计时
        startTimer() {
            let _this = this;
            if (!_this.timer) {
                _this.timer = setInterval(() => {
                    if (_this.time > 0) {
                        _this.time--;
                        sessionStorage.setItem("time", _this.time);
                    } else {
                        clearInterval(_this.timer);
                        _this.timer = null;
                        sessionStorage.removeItem("time");
                    }
                }, 1000);
            }
        },

        //找回密码
        findPwdFn() {
            this.$refs["findPwdForm"].validate(valid => {
                if (valid) {
                    this.post(
                        "/user/index/find_password",
                        {
                            mobile: this.findPwdForm.mobile,
                            code: this.findPwdForm.code,
                            password: this.findPwdForm.password
                        },
                        res => {
                            if (res.code == 200) {
                                this.openDialog("findPasswordTips"); //跳转到找回密码成功页面
                            } else {
                                this.$message.error(res.msg);
                                return;
                            }
                        }
                    );
                }
            });
        },

        //qq登录
        qqLogin() {
            let _this = this;
            _this.post("/user/index/qq", {}, function (res) {
                if (res.code == 200) {
                    window.location.href = res.data;
                }
            });
        },

        //weixin登录
        weixinLogin() {
            let _this = this;
            _this.post("/user/index/weixin", {}, function (res) {
                if (res.code == 200) {
                    window.location.href = res.data;
                }
            });
        },

        //weibo登录
        weiboLogin() { }
    },

    created() {
        if (this.$route.query.t) {
            sessionStorage.setItem("token", this.$route.query.t); //存储登录信息
            // this.$message.success("登录成功");
            this.ajaxUserInfo(); //刷新用户数据
            this.$router.push("/user/index");
        }

        this.openDialog(this.$route.query.type); //跳转到对应页面
        this.load_area(); //加载地区数据

        //赋值邀请码
        if (this.$route.query.code) {
            this.registerForm.invitationCode = this.$route.query.code;
        }

        //获取验证码
        let _time = sessionStorage.getItem("time");
        if (_time) {
            this.time = _time;
            this.startTimer();
        }

        //如果是第三方注册
        if (this.$route.query.ocode) {
            this.registerForm.openid = this.$route.query.ocode;
            this.registerForm.reg_type = this.$route.query.rt;
        }
    },

    watch: {
        $route() {
            this.openDialog(this.$route.query.type);
        }
    }
};
</script>

<style lang="less">
@import "../../styles/login.less";
</style>